import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Icon } from '../../../components/icon'
import { ContextShell } from '../../../modules/context'

export const useToastSlowerProcessing = (): void => {
  const [STORAGE_NAME] = useState('moises:banner-slower-processing')
  const { pathname, push } = useRouter()
  const {
    user,
    toast: { add: addToast }
  } = useContext(ContextShell)

  const fireToast = useCallback(() => {
    const pathBilling = user?.featureFlags?.webNewBilling
      ? '/billing/pricing/'
      : '/pricing'
    addToast({
      fixed: true,
      closable: true,
      type: 'default',
      icon: <Icon name="alert" />,
      buttonText: 'Upgrade Now',
      onConfirm: () => push(pathBilling),
      title: 'Demand is off the charts!',
      description:
        'Our processing time is longer than usual. Premium users have priority.'
    })
    // we do not want to track fireToast as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !sessionStorage.getItem(STORAGE_NAME) &&
      user?.featureFlags?.slowerProcessingTimeOnWeb &&
      !user?.subscription?.isPremium &&
      pathname !== '/not-found'
    ) {
      fireToast()
      sessionStorage.setItem(STORAGE_NAME, 'true')
    }
  }, [user, pathname, STORAGE_NAME, fireToast])
}
