import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { FirebaseAuth } from '../../../lib/firebase'
import { rudderstackIdentify } from '../../../lib/rudderstack'
import { replacePlaceholders } from '../../../lib/misc/replace-placeholders'
import { Title } from '../../../components/title'
import { Button } from '../../../components/button'
import { SocialSignIn } from '../../../components/auth/social-sign-in'
import { EmailPasswordSignIn } from '../../../components/auth/email-password-sign-in'
import { SignInProviders } from '../../../components/auth/sign-in-providers'
import { HeaderAuth } from '../../../components/auth/header-auth'
import { useAuthentication } from '../../../hooks/auth/use-authentication'
import { ContextShell } from '../../context'
import styles from './login.module.scss'

function removeLeadingSlash(str: any): string {
  if (str && str?.startsWith('/')) {
    return str?.substring(1)
  }
  return str
}

export const Login = (): ReactElement => {
  const { i18n } = useLingui()
  const { query, push } = useRouter()
  const [eventFired, setEventFired] = useState(false)
  const {
    event: { sendEvent }
  } = useContext(ContextShell)
  const [isSignIn, setIsSignIn] = useState(
    query && !Object.prototype.hasOwnProperty.call(query, 'signup')
  )
  const [isReferral] = useState(
    (query &&
      query?.redirect &&
      query.redirect.includes('claim-referral-link')) ||
      false
  )
  const isReferralPage = !isSignIn && isReferral

  const {
    error,
    loading,
    email,
    password,
    errorEmail,
    errorPassword,
    signInProviders,
    onChangeEmail,
    onFocusEmail,
    onChangePassword,
    signInApple,
    signInGoogle,
    signInTwitter,
    signInFacebook,
    signInEmail,
    signUpEmail,
    onBlur
  } = useAuthentication({
    isSignIn
  })

  const onGoResetPassword = useCallback(() => push('/reset-password'), [push])

  const toggleSignIn = useCallback(() => setIsSignIn(!isSignIn), [isSignIn])

  const onAuthenticated = useCallback(
    async (user: any) => {
      if (!user) return

      const { redirect, ...urlParams } = query
      const { currentUser } = FirebaseAuth
      const { createdAt } = currentUser?.toJSON() as any
      const accountRecentlyCreated = Date.now() - createdAt < 5000
      const eventName = accountRecentlyCreated ? 'signed_up' : 'logged_in'

      if (user.uid && !eventFired) {
        setEventFired(true)
        rudderstackIdentify(user.uid)

        sendEvent({
          userId: user.uid,
          name: eventName,
          category: eventName === 'logged_in' ? 'engagement' : 'activation',
          customAttributes: {
            authentication: user?.providerData[0]?.providerId || 'not set',
            ...(isReferralPage && {
              referralId: (query?.redirect as string)?.replace(
                /^\/claim-referral-link\/|\/$/g,
                ''
              )
            })
          }
        })
      }

      const params = new URLSearchParams(
        JSON.parse(JSON.stringify(urlParams))
      ).toString()

      window.location.href = redirect
        ? `/${removeLeadingSlash(redirect)}?${params}`
        : '/library'
    },
    [eventFired, query, sendEvent, isReferralPage]
  )

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onAuthStateChanged(onAuthenticated)

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [onAuthenticated])

  const pageTitle = useMemo(() => {
    if (isReferralPage) {
      return replacePlaceholders(t`refer_friend_invitation`, {
        user: query?.user || '',
        plan: query?.plan || ''
      })
    }

    if (!isSignIn) {
      return t`actions.signup`
    }

    return t`actions.login`
  }, [isReferralPage, isSignIn, query?.plan, query.user])

  return (
    <div className={styles.page}>
      <HeaderAuth isSignIn={isSignIn} toggleSignIn={toggleSignIn} />

      <div className={styles.container}>
        {!isSignIn ? (
          <div className={styles.aside}>
            <div className={styles.artistImageContainer}>
              <img
                className={styles.artistImage}
                alt={styles.artistLabel}
                src="//storage.googleapis.com/scala-web/assets/images/artists/eloy-casagrande-moises.png"
              />
            </div>
            <p className={styles.artistDescription}>
              {i18n._(t`eloy_quote_text`)}
            </p>
            <div className={styles.artistQuote}>
              <p className={styles.artistName}>
                {i18n._(t`eloy_quote_signature`)}
              </p>
              <p className={styles.artistLabel}>{i18n._(t`eloy_quote_end`)}</p>
            </div>
          </div>
        ) : null}

        <div className={styles.content}>
          <Title className={styles.title}>{pageTitle}</Title>

          <div className={styles.form}>
            <p className={styles.subtitle}>
              {isSignIn
                ? i18n._(t`auth.signin.label`)
                : i18n._(t`auth.signup.label`)}
            </p>

            <SocialSignIn
              isSignIn={isSignIn}
              className={styles.actions}
              signInApple={signInApple}
              signInGoogle={signInGoogle}
              signInTwitter={signInTwitter}
              signInFacebook={signInFacebook}
            />

            <p className={styles.subtitle}>
              {isSignIn
                ? i18n._(t`auth.signin.login`)
                : i18n._(t`auth.signup.email`)}
            </p>

            <div className={styles.inputs}>
              <EmailPasswordSignIn
                email={email}
                password={password}
                error={error}
                loading={loading}
                isSignIn={isSignIn}
                isReferral={isReferralPage}
                errorEmail={errorEmail}
                errorPassword={errorPassword}
                onFocusEmail={onFocusEmail}
                onChangeEmail={onChangeEmail}
                onChangePassword={onChangePassword}
                onBlur={onBlur}
                onSubmit={isSignIn ? signInEmail : signUpEmail}
              />

              {signInProviders.show && (
                <SignInProviders
                  {...signInProviders}
                  onChangeEmail={onChangeEmail}
                  signInApple={signInApple}
                  signInGoogle={signInGoogle}
                  signInTwitter={signInTwitter}
                  signInFacebook={signInFacebook}
                />
              )}
            </div>

            {isSignIn ? (
              <div className={styles.containerResetPassword}>
                <Button
                  small
                  radius={1}
                  transparent
                  id="forgot_my_password_button"
                  title={i18n._(t`actions.password.forgot`)}
                  onClick={onGoResetPassword}
                  className={styles.buttonResetPassword}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
